import {Bar, BarProps, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Area, ComposedChart} from 'recharts'
import {StyledLegend, StyledLegendWrap, StyledPerformanceChart} from './style'
import {CustomBar} from '../custom-bar/CustomBar'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {QuoteCalculationsType} from '@/features/quote/types.ts'
import {formatChartYAxis} from '@/utilities/helpers'

export const PerformanceChart = ({quoteCalculationsData}: {quoteCalculationsData: QuoteCalculationsType}) => {
    const theme = useTheme()
    const {t} = useTranslation()

    const {consumptions, production} = quoteCalculationsData

    const mapData = (
        consumptions: QuoteCalculationsType['consumptions'],
        productions: QuoteCalculationsType['production']
    ) => {
        return consumptions.map(consumption => {
            const production = productions.find(prod => prod.month === consumption.month)
            return {
                name: String(consumption.month),
                uv: production ? Math.round(production.value) : 0,
                pv: Math.round(consumption.value),
                amt: 0
            }
        })
    }

    const combinedData = [{name: '0', uv: 0, pv: 0, amt: 0}, ...mapData(consumptions, production)]

    return (
        <StyledPerformanceChart direction="column" gap={3}>
            <StyledLegendWrap gap={2}>
                <StyledLegend gap={1} align="center" color={'#E4E7EC'}>
                    <span />
                    <p>{t('quote:performance:consumption_legend')}</p>
                </StyledLegend>

                <StyledLegend gap={1} align="center" color={theme.palette.primary[500]}>
                    <span />
                    <p>{t('quote:performance:production_legend')}</p>
                </StyledLegend>
            </StyledLegendWrap>

            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={combinedData} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                    <defs>
                        <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="1">
                            <stop offset="0%" stopColor={theme.palette.primary[600]} />
                            <stop offset="100%" stopColor={theme.palette.primary[500]} />
                        </linearGradient>
                    </defs>

                    <XAxis
                        dataKey="name"
                        fontSize={12}
                        stroke={theme.palette.primary[600]}
                        strokeWidth={0}
                        scale="point"
                        label={{
                            value: t('commons:months'),
                            fill: theme.palette.primary[600],
                            position: 'insideBottom',
                            offset: -10,
                            fontSize: 14
                        }}
                    />

                    <YAxis
                        fontSize={12}
                        stroke={theme.palette.primary[600]}
                        strokeWidth={0}
                        domain={[0, 'auto']}
                        label={{
                            value: t('project_details:energy_section:units'),
                            fill: theme.palette.primary[600],
                            position: 'left',
                            angle: -90,
                            offset: 0,
                            fontSize: 14
                        }}
                        tickFormatter={value => formatChartYAxis(value)}
                    />

                    <CartesianGrid vertical={false} stroke={theme.palette.primary[100]} />

                    <Area type="monotone" dataKey="pv" stroke="none" fill="#E4E7EC" baseLine={0} />
                    <Bar dataKey="uv" shape={(props: BarProps) => <CustomBar {...props} />} fill="url(#gradient)" />
                </ComposedChart>
            </ResponsiveContainer>
        </StyledPerformanceChart>
    )
}
