import {z} from 'zod'
import {
    OrientationE,
    PanelsGridE,
    PlacementInfoE,
    RoofTypeE,
    SegmentDataType,
    TriangleTypeE
} from '@/features/system-configuration/types'
import {NUMBERS_ONLY_REGEX} from '@/utilities/constants/regex'
import {ComponentSchema} from '@/features/catalog-components/services/catalogComponents.schema'
import {Quaternion, Vector3} from 'three'

export const enum PHOTOVOLTAIC_FIELD_MODEL {
    MeterPower = 'meterPower',
    SystemPower = 'systemPower',
    PanelProductId = 'panelProductId',
    OptimizerProductId = 'optimizerProductId',
    AccumulationCapacity = 'accumulationCapacity',
    BatteryProductId = 'batteryProductId'
}

export const PhotovoltaicFieldFormSchema = z.object({
    [PHOTOVOLTAIC_FIELD_MODEL.MeterPower]: z.string().regex(NUMBERS_ONLY_REGEX, {message: 'errors:numbers_only'}),
    [PHOTOVOLTAIC_FIELD_MODEL.SystemPower]: z.string().regex(NUMBERS_ONLY_REGEX, {message: 'errors:numbers_only'}),
    [PHOTOVOLTAIC_FIELD_MODEL.PanelProductId]: z.string(),
    [PHOTOVOLTAIC_FIELD_MODEL.OptimizerProductId]: z.string().optional().nullable(),
    [PHOTOVOLTAIC_FIELD_MODEL.AccumulationCapacity]: z.string().optional().nullable(),
    [PHOTOVOLTAIC_FIELD_MODEL.BatteryProductId]: z.string().optional().nullable()
})

export const enum SYSTEM_SETTINGS_MODEL {
    Azimuth = 'azimuth',
    Inclination = 'inclination',
    PanelsInclination = 'panelsInclination',
    HorizontalDistanceModules = 'horizontalDistanceModules',
    HasFlapRacks = 'hasFlapRacks',
    FlapRacksDegrees = 'flapRacksDegrees',
    Elevation = 'elevation',
    HasAutoElevation = 'hasAutoElevation',
    VerticalDistanceModules = 'verticalDistanceModules',
    AnnualSunShadingPercentage = 'annualSunShadingPercentage',
    HorizontalDistance = 'horizontalDistance',
    VerticalDistance = 'verticalDistance',
    PanelPosition = 'panelPosition'
}

export const settingsFormSchema = z.object({
    [SYSTEM_SETTINGS_MODEL.Azimuth]: z
        .string()
        .refine(val => parseFloat(val) >= -180, {message: 'errors:must_be_greater_than_180'})
        .refine(val => parseFloat(val) <= 180, {message: 'errors:must_be_smaller_than_180'})
        .nullable()
        .or(z.literal('')),
    [SYSTEM_SETTINGS_MODEL.Inclination]: z
        .string()
        .refine(val => parseFloat(val) >= 0, {message: 'errors:must_be_greater_than_0'})
        .refine(val => parseFloat(val) <= 90, {message: 'errors:must_be_smaller_than_90'})
        .nullable()
        .or(z.literal('')),
    [SYSTEM_SETTINGS_MODEL.PanelsInclination]: z.nativeEnum(OrientationE),
    [SYSTEM_SETTINGS_MODEL.HasFlapRacks]: z.boolean(),
    [SYSTEM_SETTINGS_MODEL.FlapRacksDegrees]: z
        .string()
        .refine(val => parseFloat(val) >= 0, {message: 'errors:must_be_greater_than_0'})
        .refine(val => parseFloat(val) <= 360, {message: 'errors:must_be_smaller_than_360'})
        .nullable()
        .or(z.literal(''))
        .optional(),
    [SYSTEM_SETTINGS_MODEL.Elevation]: z.string().optional(),
    [SYSTEM_SETTINGS_MODEL.HorizontalDistanceModules]: z
        .string()
        .regex(NUMBERS_ONLY_REGEX, {message: 'errors:numbers_only'})
        .optional(),

    [SYSTEM_SETTINGS_MODEL.HasAutoElevation]: z.boolean(),
    [SYSTEM_SETTINGS_MODEL.VerticalDistanceModules]: z
        .string()
        .regex(NUMBERS_ONLY_REGEX, {message: 'errors:numbers_only'})
        .optional(),

    [SYSTEM_SETTINGS_MODEL.AnnualSunShadingPercentage]: z.number().min(0).max(100),
    [SYSTEM_SETTINGS_MODEL.HorizontalDistance]: z
        .string()
        .regex(NUMBERS_ONLY_REGEX, {message: 'errors:numbers_only'})
        .optional(),
    [SYSTEM_SETTINGS_MODEL.VerticalDistance]: z
        .string()
        .regex(NUMBERS_ONLY_REGEX, {message: 'errors:numbers_only'})
        .optional(),
    [SYSTEM_SETTINGS_MODEL.PanelPosition]: z.nativeEnum(PlacementInfoE)
})

export const UserDataSchema = z.object({
    id: z.string(),
    type: z.nativeEnum(PanelsGridE),
    intersectPoint: z.instanceof(Vector3),
    groupId: z.string()
})

export const StoredMatrixTypeSchema = z.object({
    position: z.array(z.number()).nullable(), // Updated to allow for empty arrays
    quaternion: z.array(z.number()).nullable(), // Updated to allow for empty arrays
    userData: UserDataSchema
})

export const StoredGroupOfPanelsMatrixTypeSchema = z.object({
    groupId: z.string(),
    matrix: z.array(z.array(StoredMatrixTypeSchema)).nullable() // Updated to allow null for matrix
})

export const LocationSchema = z.object({
    lat: z.number(),
    lng: z.number(),
    alt: z.number().optional(),
    quaternion: z.custom<Quaternion>().optional()
})

// PANELS GROUP
export const PanelsGroupDesignSchema = z
    .object({
        panelIds: z.array(z.string()).optional(),
        panelsMatrix: StoredGroupOfPanelsMatrixTypeSchema.optional(),
        panels: z.array(LocationSchema),
        groupName: z.number().optional(),
        pitchDegrees: z.union([z.string(), z.number()]).optional(),
        isInclinationChanged: z.boolean().optional()
    })
    .nullable()

//TODO: Add correct errors
export const PanelsGroupConfigurationSchema = z.object({
    id: z.string().uuid(),
    projectSystemId: z.string().uuid(),
    azimuth: z.string().nullable(),
    inclination: z.string().nullable(),
    panelsInclination: z.nativeEnum(OrientationE),
    hasFlapRacks: z.boolean().optional(),
    hasAutoElevation: z.boolean().optional(),
    flapRacksDegrees: z.string().nullable().optional(),
    elevation: z.string().nullable().optional(),
    annualSunShadingPercentage: z.number().min(0).max(100),
    horizontalDistanceModules: z.number().positive(),
    verticalDistanceModules: z.number().positive(),
    panelPosition: z.nativeEnum(PlacementInfoE),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    panelsQuantity: z.number(),
    panelsDesign: PanelsGroupDesignSchema
})

export const PivotSchema = z.object({
    pivot_product_id: z.string().uuid(),
    pivot_attribute_id: z.string().uuid(),
    pivot_value_string: z.string(),
    pivot_value_number: z.string().nullable()
})
export const ProductsAttributeSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    slug: z.string(),
    pivot: PivotSchema
})
export const ProductResponseSchema = z.object({
    id: z.string().uuid(),
    code: z.string(),
    name: z.string(),
    slug: z.string(),
    categoryId: z.string().uuid(),
    price: z.string().optional().nullable(),
    finalPrice: z.string(),
    available: z.number(),
    attributes: z.array(ProductsAttributeSchema)
})

export const SelectSchema = z.object({
    extraPayload: ProductResponseSchema.optional().nullable(),
    id: z.string().uuid(),
    label: z.string(),
    value: z.string().uuid()
})
export const BallastCalculations = z.object({
    ballastsNumber: z.number().optional(),
    centralClampsNumber: z.number().optional(),
    endClampsNumber: z.number().optional(),
    bituminousMatsNumber: z.number().optional()
})
export const TrianglesCalculations = z.object({
    trianglesNumber: z.number().optional(),
    centralClampsNumber: z.number().optional(),
    endClampsNumber: z.number().optional(),
    expansionScrewsNumber: z.number().optional(),
    railClampsNumber: z.number().optional()
})
export const RailsCalculations = z.object({
    railsNumber: z.number().optional(),
    splice: z.number().optional(),
    centralClampsNumber: z.number().optional(),
    endClampsNumber: z.number().optional()
})
export const ScrewAndBracketsCalculations = z.object({
    screwsAndBracketsNumber: z.number().optional()
})
//FASTENING COMPONENT CONFIGURATION
export const FasteningConfigurationResponseSchema = z.object({
    id: z.string().uuid().optional(),
    projectSystemId: z.string().uuid().optional(),
    ballastInclination: z.number().optional(),
    ballastInclinationDirection: z.string().nullable().optional(),
    ballastModel: z.string().uuid().nullish(),
    ballastProductId: z.string().uuid().nullish(),
    ballastProduct: ComponentSchema.optional().nullable(),
    triangleType: z.nativeEnum(TriangleTypeE).optional(),
    railProductId: z.string().uuid().optional().nullish(),
    railProduct: ComponentSchema.optional().nullable(),
    screwAndBracketProductId: z.string().uuid().optional(),
    screwAndBracketProduct: ComponentSchema.optional().nullable(),
    quantitiesJson: z.object({
        railsQuantities: RailsCalculations,
        ballastsQuantities: BallastCalculations,
        trianglesQuantities: TrianglesCalculations,
        screwsAndBracketsQuantities: ScrewAndBracketsCalculations
    }),
    roofType: z.nativeEnum(RoofTypeE)
})

//POWER DISTRIBUTION
export const PowerDistributionPayloadSchema = z.object({
    powerDistributionsProductsIds: z.array(z.string().uuid())
})

export const PowerDistributionResponseSchema = z.object({
    id: z.string().uuid(),
    projectSystemId: z.string().uuid(),
    powerDistributionProductId: z.string().uuid()
})
export const CreatePowerDistributionResponseSchema = z.array(PowerDistributionResponseSchema)
export const PowerDistributionProductSchema = z.object({
    id: z.string().uuid(),
    projectSystemId: z.string().uuid(),
    powerDistributionProductId: z.string().uuid(),
    product: ProductResponseSchema.optional()
})
export const PowerDistributionStoreSchema = z.object({
    sideACIds: z.array(SelectSchema).optional(),
    sideDCIds: z.array(SelectSchema).optional(),
    singleBoxIds: z.array(SelectSchema).optional(),
    otherIds: z.array(SelectSchema).optional()
})

//SYSTEM CONFIGURATION

export const enum ROOF_AREA_MODEL {
    FirstRoofMeasure = 'firstRoofMeasure',
    SecondRoofMeasure = 'secondRoofMeasure',
    RoofOrientation = 'roofOrientation'
}

export const RoofAreaFormSchema = z.object({
    roofType: z.nativeEnum(RoofTypeE),
    firstRoofMeasure: z.string(),
    secondRoofMeasure: z.string(),
    roofOrientation: z.nativeEnum(OrientationE)
})

export const DesignOptionsSchema = z.object({
    numberOfPanels: z.number().optional(),
    panelPower: z.number().optional(),
    systemPower: z.number().optional(),
    isBatterySelected: z.boolean().optional(),
    batteryCapacity: z.number().optional(),
    batteryStructure: z.string().nullish(),
    accumulationCapacity: z.number().optional().optional()
})
export const FasteningOptionsSchema = z.object({
    firstRoofMeasure: z.number().optional(),
    secondRoofMeasure: z.number().optional(),
    orientation: z.nativeEnum(OrientationE).optional(),
    panelLength: z.number().optional(),
    panelWidth: z.number().optional(),
    rowValues: z.array(z.number()).optional(),
    panelsRows: z.number().optional(),
    railModelWidth: z.number().optional(),
    triangleType: z.string().optional(),
    isBallastSelected: z.boolean().optional(),
    isTrianglesSelected: z.boolean().optional(),
    isRailsSelected: z.boolean().optional(),
    isScrewsAndBracketsSelected: z.boolean().optional()
})

export const ProductionOptionsSchema = z.object({
    groupsPositions: z.array(
        z
            .object({
                latitude: z.number(),
                longitude: z.number(),
                azimuth: z.string().nullable(),
                inclination: z.string().nullable(),
                annualSunShadingPercentage: z.number().optional()
            })
            .optional()
    ),
    inclination: z.string().optional(),
    azimuth: z.string().optional(),
    annualConsumption: z.number().optional(),
    systemSize: z.number().optional()
})

export const ProjectSystemCalculationPayloadSchema = z.object({
    designOptions: DesignOptionsSchema.optional(),
    fasteningOptions: FasteningOptionsSchema.optional(),
    cablesOptions: z.object({}).optional(),
    productionOptions: ProductionOptionsSchema.optional()
})

export const DesignCalculationsResponseSchema = z.object({
    peakPanelPower: z.number().optional(),
    maxNumberOfPanels: z.number().optional(),
    fieldSystemPower: z.number().optional(),
    numberMaxBatteries: z.number().optional(),
    effectiveAccumulation: z.number().optional(),
    inverter: ProductResponseSchema.optional().nullable(),
    monitoringModules: z.array(ProductResponseSchema.optional().nullable())
})

export const BallastProductsSchema = z.object({
    bituminousMatProduct: ProductResponseSchema.optional().nullable(),
    centralEndClampProduct: ProductResponseSchema.optional().nullable(),
    endClampProduct: ProductResponseSchema.optional().nullable()
})

export const RailsProductsSchema = z.object({
    centralEndClampProduct: ProductResponseSchema.optional().nullable(),
    endClampProduct: ProductResponseSchema.optional().nullable(),
    spliceProduct: ProductResponseSchema.optional().nullable()
})

export const TrianglesProductsSchema = z.object({
    expansionBoltsProduct: ProductResponseSchema.optional().nullable(),
    railsClampsProduct: ProductResponseSchema.optional().nullable(),
    triangleProduct: ProductResponseSchema.optional().nullable()
})

export const FasteningComponentsResponseSchema = z.object({
    ballastCalculations: BallastCalculations.optional(),
    trianglesCalculations: TrianglesCalculations.optional(),
    railsCalculations: RailsCalculations.optional(),
    screwAndBracketsCalculations: ScrewAndBracketsCalculations.optional(),
    fasteningComponentsProducts: z.object({
        ballastProducts: BallastProductsSchema,
        railsProducts: RailsProductsSchema,
        trianglesProducts: z.object({
            expansionBoltsProduct: ProductResponseSchema.optional().nullable(),
            railsClampsProduct: ProductResponseSchema.optional().nullable(),
            triangleProduct: ProductResponseSchema.optional().nullable(),
            trianglesCentralClampProduct: ProductResponseSchema.optional().nullable(),
            trianglesEndClampProduct: ProductResponseSchema.optional().nullable()
        })
    })
})

export const ProductionCalculationsSchema = z.object({
    consumptionOffset: z.number(),
    annualOutput: z.number(),
    systemSize: z.number()
})

export const ProjectSystemCalculationResponseSchema = z.object({
    designCalculations: DesignCalculationsResponseSchema.optional(),
    fasteningCalculations: z
        .object({
            roofSurface: z.number().optional(),
            firstRowMeasure: z.number().optional(),
            secondRowMeasure: z.number().optional(),
            fasteningComponentsCalculations: FasteningComponentsResponseSchema.optional()
        })
        .optional(),
    cablesCalculations: z
        .object({
            connectorsNumber: z.number().optional()
        })
        .optional(),
    productionCalculations: ProductionCalculationsSchema.optional()
})

export type ProjectSystemCalculationResponseSchema = z.infer<typeof ProjectSystemCalculationResponseSchema>

export const SystemConfigurationSchema = z.object({
    id: z.string(),
    name: z.string().optional(),
    projectId: z.string(),
    meterPower: z.string().nullable(),
    systemPower: z.string().nullable(),
    panelProductId: z.string().nullable(),
    optimizerProductId: z.string().nullable(),
    batteryProductId: z.string().nullable(),
    behiveAndSwarm: z.boolean().nullable(),
    accumulationCapacity: z.string().nullable(),
    finalPrice: z.string().optional().nullable(),
    effectiveAccumulation: z.number().nullable(),
    systemSize: z.number().nullable(),
    numberMaxBatteries: z.number().nullable(),
    panelProduct: ComponentSchema.nullable(),
    optimizerProduct: ComponentSchema.nullable(),
    batteryProduct: ComponentSchema.nullable(),
    firstRoofMeasure: z.string().optional(),
    secondRoofMeasure: z.string().optional(),
    projectFasteningComponent: FasteningConfigurationResponseSchema.nullable(),
    roofOrientation: z.nativeEnum(OrientationE),
    roofSurface: z.string(),
    roofType: z.nativeEnum(RoofTypeE),
    fieldSystemPower: z.string().nullable(),
    numberMaxPanels: z.number().nullable(),
    cableProductId: z.string().nullable().optional(),
    cableProduct: ComponentSchema.nullable().optional(),
    fasteningKitProduct: ComponentSchema.nullable().optional(),
    connectorProduct: ComponentSchema.nullable().optional(),
    chargingStationProduct: ComponentSchema.nullable().optional(),
    chargingStationActive: z.boolean().nullable().optional(),
    triangle_active: z.boolean().nullable().optional(),
    blackCableProductId: z.string().nullable().optional(),
    redCableProductId: z.string().nullable().optional(),
    blackCableLength: z.string().optional(),
    redCableLength: z.string().optional(),
    cableLength: z.string().nullable().optional(),
    connectorProductId: z.string().nullable().optional(),
    connectorsCount: z.number().nullable().optional(), //TODO ask BE to add
    chargingStationProductId: z.string().nullable().optional(),
    sideACIds: z.array(z.string().uuid()).optional(), //TODO ask BE to add
    sideDCIds: z.array(z.string().uuid()).optional(), //TODO ask BE to add
    singleBoxIds: z.array(z.string().uuid()).optional(), //TODO ask BE to add
    otherIds: z.array(z.string().uuid()).optional(), //TODO ask BE to add
    connectorsProductId: z.string().uuid().optional(), //TODO ask BE to add
    projectsPanelsGroups: z.array(PanelsGroupConfigurationSchema).nullable(),
    projectPowerDistributions: CreatePowerDistributionResponseSchema,
    quote: z.object({id: z.string(), status: z.string()}),
    type: z.string().optional().nullable(),
    quantitiesJson: z
        .object({
            numberMaxBatteries: z.number().optional(),
            panelsQuantity: z.string().optional().nullable(),
            connectorsNumber: z.string().optional().nullable(),
            stepConfigurations: z.number()
        })
        .optional(),
    roofSegmentsJson: z.object({roofSegments: z.array(z.custom<SegmentDataType>()).optional()}).optional(),
    calculateJson: ProjectSystemCalculationResponseSchema.optional().nullable(),
    blackCableProduct: ComponentSchema.optional().nullable(),
    redCableProduct: ComponentSchema.optional().nullable(),
    cableSection: z.string().optional().nullable()
})

export type SystemConfigurationSchema = z.infer<typeof SystemConfigurationSchema>

export const SelectTypeProductRemap = z.object({
    panelProductId: z.object({
        value: z.string(),
        label: z.string()
    }),
    optimizerProductId: z.object({
        value: z.string(),
        label: z.string()
    }),
    batteryProductId: z.object({
        id: z.string(),
        label: z.string()
    }),
    accumulationCapacity: z.object({
        value: z.string(),
        label: z.string()
    }),
    roofOrientation: z.object({
        value: z.string(),
        label: z.string()
    }),
    ballastProductId: z
        .object({
            value: z.string(),
            label: z.string()
        })
        .optional(),
    ballast: z
        .object({
            value: z.string(),
            label: z.string(),
            ballastInclinationDirection: z.string()
        })
        .optional(),
    ballastModel: z.string().optional(),
    triangleType: z
        .object({
            value: z.string(),
            label: z.string()
        })
        .nullish(),
    railProductId: z
        .object({
            value: z.string(),
            label: z.string()
        })
        .optional(),
    screwAndBracketProductId: z
        .object({
            value: z.string(),
            label: z.string()
        })
        .nullish(),
    cableSection: z
        .object({
            value: z.string(),
            label: z.string()
        })
        .nullish(),
    blackCableLength: z
        .object({
            value: z.string(),
            label: z.string()
        })
        .nullish(),
    redCableLength: z
        .object({
            value: z.string(),
            label: z.string()
        })
        .nullish(),
    connectorProductId: z
        .object({
            id: z.string()
        })
        .nullish(),
    connectorsCount: z.union([z.string(), z.number()]).optional().nullish(),
    chargingStationProductId: z
        .object({
            id: z.string()
        })
        .nullish(),
    sideAC: z
        .array(
            z.object({
                id: z.string().optional()
            })
        )
        .optional(),
    sideDC: z
        .array(
            z.object({
                id: z.string().optional()
            })
        )
        .optional(),
    box: z
        .array(
            z.object({
                id: z.string().optional()
            })
        )
        .optional(),
    other: z
        .array(
            z.object({
                id: z.string().optional()
            })
        )
        .optional()
})

export type SelectTypeProductRemap = z.infer<typeof SelectTypeProductRemap>

export const ProductionCalculationResponseSchema = z.object({
    annualOutput: z.number().optional(),
    consumptionOffset: z.number().optional()
})

export const EditSystemConfigurationPayloadSchema = z.object({
    name: z.string().optional(),
    meterPower: z.string().optional(),
    systemPower: z.string().optional(),
    panelProductId: z.string().uuid().optional(),
    optimizerProductId: z.string().uuid().optional().nullable(),
    batteryProductId: z.string().uuid().optional(),
    accumulationCapacity: z.string().optional().nullable(),
    roofType: z.nativeEnum(RoofTypeE).optional(),
    roofSurface: z.string().optional(),
    firstRoofMeasure: z.string().optional(),
    secondRoofMeasure: z.string().optional(),
    roofOrientation: z.nativeEnum(OrientationE).optional(),
    behiveAndSwarm: z.boolean().optional(),
    connectorProductId: z.string().uuid().optional().nullish(),
    cableProductId: z.string().uuid().optional().nullish(),
    cableLength: z.string().optional().nullish(),
    cableSectionId: z.string().uuid().optional(),
    chargingStationProductId: z.string().uuid().optional().nullish(),
    sideACIds: z.array(z.string().uuid()).optional(),
    sideDCIds: z.array(z.string().uuid()).optional(),
    singleBoxIds: z.array(z.string().uuid()).optional(),
    otherIds: z.array(z.string().uuid()).optional(),
    connectorsCount: z.number().optional().nullish(),
    panelPower: z.string().optional(),
    panelNumber: z.number().optional(),
    batteryCapacity: z.string().optional().nullable(),
    longPanelSide: z.string().optional().nullable(),
    shortPanelSide: z.string().optional().nullable(),
    railModelWidth: z.string().optional().nullable(),
    batteryStructure: z.string().optional().nullable(),
    panelProduct: SelectSchema.optional().nullable(),
    optimizerProduct: SelectSchema.optional().nullable(),
    batteryProduct: SelectSchema.optional().nullable(),
    ballastProduct: SelectSchema.optional().nullable(),
    railProduct: SelectSchema.optional().nullable(),
    screwAndBracketsProduct: SelectSchema.optional().nullable(),
    cableProduct: SelectSchema.optional().nullable(),
    cableSection: z.string().optional().nullable(),
    connectorProduct: SelectSchema.optional().nullable(),
    chargingStationProduct: SelectSchema.optional().nullable(),
    fastenignProductKit: SelectSchema.optional().nullable(),
    fasteningProductKitId: z.string().optional().nullable(),
    isFasteningKitSelected: z.boolean().optional().nullable(),
    redCableProduct: SelectSchema.optional().nullable(),
    redCableProductId: z.string().optional().nullable(),
    redCableLength: z.string().optional().nullable(),
    blackCableProduct: SelectSchema.optional().nullable(),
    blackCableProductId: z.string().optional().nullable(),
    blackCableLength: z.string().optional().nullable(),
    cerberoKitProduct: SelectSchema.optional().nullable(),
    triangleType: z.string().optional().nullable(),
    isCompleted: z.boolean().optional(),
    optimizersNumber: z.number().optional(),
    numberMaxBatteries: z.number().optional(),
    isBallastSelected: z.boolean().optional(),
    isTrianglesSelected: z.boolean().optional(),
    isRailsSelected: z.boolean().optional(),
    isScrewsAndBracketsSelected: z.boolean().optional(),
    quoteStatusReady: z.boolean().optional().nullable(),
    quantitiesJson: z
        .object({
            numberMaxBatteries: z.number().optional(),
            panelsQuantity: z.string().optional().nullable(),
            connectorsNumber: z.number().optional().nullable()
        })
        .optional(),
    roofSegmentsJson: z.object({roofSegments: z.array(z.custom<SegmentDataType>()).optional()}).optional(),
    calculateJson: ProjectSystemCalculationResponseSchema.optional().nullable(),
    productionCalculateJson: ProductionCalculationResponseSchema.optional()
})
